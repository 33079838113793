import React from "react"
import Layout from "@components/layout"
import Paragraph from "@components/elements/paragraph"
import Heading from "@components/elements/heading"
import Div from "@components/elements/div"
import Flex from "@components/elements/flex"
import { SocialProofSection } from "@sections/home/social-proof"
import styled, { useTheme } from "styled-components"
import { HighGradient } from "@components/common/gradient"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { processImages } from "@utils/process-images"
import { motion } from "framer-motion"
import { ServiceLibraryAnimation } from "@components/animated/service-library"
import { NeutralCTA } from "@components/cta/eop/neutral"
import media from "../styles/media"
import { LogoCarousel } from "@components/brand/logo-carousel"
import Button from "@components/elements/button"
import Span from "@components/elements/span"
import { ReactComponent as KlipsLogo } from "@images/comparison/klips.svg"
import Image from "@components/image"
import { GlassyDiv } from "@components/elements/special"
import { useGradient } from "@hooks/use-gradient"
import Anchor from "@components/elements/anchor"
import { Testimony } from "./klips"

const Wave1 = styled.svg`
  position: absolute;
  top: -14rem;
  left: 26%;
  mix-blend-mode: color-dodge;
  opacity: 0.9;
  ${media.sm`
    display: none;
  `}
`

const Wave2 = styled.svg`
  position: absolute;
  top: -11rem;
  right: 34%;
  filter: drop-shadow(40px 34px 40px rgba(0, 0, 0, 0.5));
  opacity: 0.9;
  ${media.sm`
    display: none;
  `}
`

const pathVariants = {
  rest: {
    opacity: 0.8,
    d: "M1684 330.5C1887.89 754.85 1616.14 368.101 1209 409.71C818.108 449.657 761.836 1206.64 224.838 531.991C-312.16 -142.66 497.363 -79.9934 1176.62 198.574C1520.03 61.7948 1480.11 -93.8503 1684 330.5Z",
  },
  wave: {
    opacity: 1,
    d: "M1628 298.453C1831.89 722.804 1617.86 295.277 1152.99 377.663C860.5 429.5 692.061 1320 209 615.134C-274.061 -89.7319 441.364 -112.04 1120.62 166.528C1464.03 29.7482 1424.11 -125.897 1628 298.453Z",
    transition: {
      duration: 36,
      ease: "easeInOut",
      repeat: Infinity,
      repeatType: "reverse",
    },
  },
}

const pathVariants2 = {
  rest: {
    opacity: 1,
    d: "M71.4927 229.502C367.498 247.501 778.781 187.224 975.656 386.205C1122.8 534.917 1345.35 957.218 1632.74 441.37C1920.14 -74.4774 1446.16 50.2199 1064.46 14.276C461.451 -42.5076 -224.513 211.504 71.4927 229.502Z",
  },
  wave: {
    opacity: 0.8,
    d: "M71.4927 230.356C367.498 248.354 771.5 84.0003 975.657 387.058C1179.81 690.116 1324.48 955.447 1632.74 442.223C1941 -71 1446.17 51.073 1064.46 15.1291C461.451 -41.6545 -224.513 212.357 71.4927 230.356Z",
    transition: {
      duration: 36,
      ease: "easeInOut",
      repeat: Infinity,
      repeatType: "reverse",
    },
  },
}

const lineVariants = {
  rest: {
    d: "M1 184.348C367.5 162.072 212 744.348 671 744.348C907 744.348 570.088 32.4516 773.835 0.735167",
  },
  wave: {
    d: "M1 184.348C486.5 250 212 744.348 671 744.348C907 744.348 570.088 32.4516 773.835 0.735167",
    transition: {
      duration: 16,
      ease: "easeInOut",
      repeat: Infinity,
      repeatType: "reverse",
    },
  },
}

const lineVariants2 = {
  rest: {
    d: "M680.148 397.523C771.716 575.678 1049.84 968.536 1337.23 452.689C1624.63 -63.1589 1190.93 -6.17422 768.952 25.5946C-837 146.5 552.393 148.964 680.148 397.523Z",
  },
  wave: {
    d: "M577.349 2.88379C369.458 -47.7085 351.5 758.5 110.779 702.622C-129.941 646.744 149.98 23.3888 0.319105 2.88387",
    transition: {
      duration: 16,
      ease: "easeInOut",
      repeat: Infinity,
      repeatType: "reverse",
    },
  },
}

const iconVariant1 = {
  rest: {
    y: 0,
  },
  wave: {
    y: 50,
    transition: {
      duration: 10,
      ease: "easeInOut",
      repeat: Infinity,
      repeatType: "reverse",
    },
  },
}

const iconVariant2 = {
  rest: {
    y: 0,
  },
  wave: {
    y: 50,
    transition: {
      delay: 1,
      duration: 10,
      ease: "easeInOut",
      repeat: Infinity,
      repeatType: "reverse",
    },
  },
}

const iconVariant3 = {
  rest: {
    y: 0,
  },
  wave: {
    y: 70,
    transition: {
      delay: 2,
      duration: 10,
      ease: "easeInOut",
      repeat: Infinity,
      repeatType: "reverse",
    },
  },
}

const iconVariant4 = {
  rest: {
    y: 0,
  },
  wave: {
    y: 60,
    transition: {
      delay: 3,
      duration: 10,
      ease: "easeInOut",
      repeat: Infinity,
      repeatType: "reverse",
    },
  },
}

const TextWrapper = styled(Flex)`
  ${media.sm_up`
    align-items: center;
    text-align: center;
  `}
`

const HomePage = ({ data }) => {
  const { color, gradient } = useTheme()
  const images = processImages(data.images.edges)
  const icons = processImages(data.icons.edges)
  const { gradient: imageGradient } = useGradient(true)

  return (
    <Layout
      fullWidth
      marginless
      login
      seo={{
        title: "Make Data Everybody’s Business",
        description: `Klipfolio is a powerful dashboard and reporting analytics platform that helps businesses of all sizes make smarter and faster decisions.`,
      }}
    >
      <Div
        position="relative"
        background={color.indigo700}
        maxWidth="100%"
        width="100%"
        overflow="hidden"
      >
        <Div
          left="0"
          right="0"
          maxWidth="1600px"
          position="absolute"
          width="100%"
          top="-8rem"
        >
          <HighGradient
            style={{ minWidth: "800px" }}
            top="18rem"
            src={data.gradient.cdn}
          />
          <Wave1
            width="1754"
            height="940"
            viewBox="0 0 1754 940"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <motion.path
                d="M1684 330.5C1887.89 754.85 1616.14 368.101 1209 409.71C818.108 449.657 761.836 1206.64 224.838 531.991C-312.16 -142.66 497.363 -79.9934 1176.62 198.574C1520.03 61.7948 1480.11 -93.8503 1684 330.5Z"
                animate="wave"
                variants={pathVariants}
                fill="url(#paint0_linear_274_8305)"
              />
            </g>
            <g
              style={{
                filter: "drop-shadow(0px 34px 0px #0F1124)",
                opacity: 0.5,
                mixBlendMode: "color-dodge",
              }}
            >
              <motion.path
                d="M1684 330.5C1887.89 754.85 1616.14 368.101 1209 409.71C818.108 449.657 761.836 1206.64 224.838 531.991C-312.16 -142.66 497.363 -79.9934 1176.62 198.574C1520.03 61.7948 1480.11 -93.8503 1684 330.5Z"
                animate="wave"
                variants={pathVariants}
                fill="url(#paint0_linear_274_8305)"
              />
            </g>
            <defs>
              <linearGradient
                id="paint0_linear_274_8305"
                x1="889.678"
                y1="750.155"
                x2="1071.55"
                y2="479.313"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#7E55B2" />
                <stop offset="1" stopColor="#38448A" stopOpacity="0" />
              </linearGradient>
            </defs>
          </Wave1>
          <Wave2
            width="1810"
            height="806"
            viewBox="0 0 1810 806"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <motion.path
              d="M71.4927 229.502C367.498 247.501 778.781 187.224 975.656 386.205C1122.8 534.917 1345.35 957.218 1632.74 441.37C1920.14 -74.4774 1446.16 50.2199 1064.46 14.276C461.451 -42.5076 -224.513 211.504 71.4927 229.502Z"
              animate="wave"
              variants={pathVariants2}
              fill="url(#paint0_linear_312_22661)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_312_22661"
                x1="565.221"
                y1="671.582"
                x2="197.02"
                y2="152.585"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#75E1DA" />
                <stop offset="1" stopColor="#38448A" stopOpacity="0" />
              </linearGradient>
            </defs>
          </Wave2>
          <Div
            position="absolute"
            width="100%"
            height="700px"
            top="11rem"
            background="linear-gradient(180deg, rgba(15, 17, 36, 0.00) 10%, #0F1124 60%)"
          ></Div>
          <Div hideOnSm>
            <svg
              width="774"
              height="756"
              viewBox="0 0 774 756"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ position: "absolute", top: "-6rem", right: "51%" }}
            >
              <g style={{ mixBlendMode: "plus-lighter" }}>
                <motion.path
                  animate="wave"
                  variants={lineVariants}
                  d="M1 184.348C367.5 162.072 212 744.348 671 744.348C907 744.348 570.088 32.4516 773.835 0.735167"
                  stroke="url(#paint0_linear_331_36301)"
                />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear_331_36301"
                  x1="405"
                  y1="542.348"
                  x2="641.158"
                  y2="519.444"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#34AD78" />
                  <stop offset="1" stopColor="#424A94" stopOpacity="0" />
                </linearGradient>
              </defs>
            </svg>
            <svg
              width="578"
              height="719"
              viewBox="0 0 578 719"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                position: "absolute",
                top: "6rem",
                left: "56%",
              }}
            >
              <g style={{ mixBlendMode: "plus-lighter" }}>
                <motion.path
                  animate="wave"
                  variants={lineVariants2}
                  d="M577.5 2.88363C369.609 -47.7087 332.561 839.749 110.93 702.622C-92.9417 576.483 150.131 23.3887 0.469721 2.8837"
                  stroke="url(#paint0_linear_331_36304)"
                />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear_331_36304"
                  x1="386.853"
                  y1="352.96"
                  x2="146.323"
                  y2="402.293"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#707EFA" />
                  <stop offset="1" stopColor="#424A94" stopOpacity="0" />
                </linearGradient>
              </defs>
            </svg>
          </Div>
          <Div hideOnMd position="relative" height="40px">
            <Image
              width={40}
              height={40}
              as={motion.img}
              variants={iconVariant1}
              animate="wave"
              style={{ position: "absolute", top: "30rem", left: "12%" }}
              file={icons["b017da8b-6c31-47ff-a77a-9f75cf6fb353"]}
            />
            <Image
              width={40}
              height={40}
              as={motion.img}
              variants={iconVariant2}
              animate="wave"
              style={{ position: "absolute", top: "34rem", left: "20%" }}
              file={icons["3efdd553-4448-4e9e-9e5f-dabf2647b45c"]}
            />
            <Image
              width={40}
              height={40}
              as={motion.img}
              variants={iconVariant3}
              animate="wave"
              style={{ position: "absolute", top: "38rem", left: "15%" }}
              file={icons["3aa397c0-c54c-4c24-9d6a-fa2e7641dfe9"]}
            />
            <Image
              width={40}
              height={40}
              as={motion.img}
              variants={iconVariant4}
              animate="wave"
              style={{ position: "absolute", top: "40rem", left: "23%" }}
              file={icons["9e4c6e11-0e74-45b6-8557-3f3291b6ec1f"]}
            />

            <Image
              width={40}
              height={40}
              as={motion.img}
              variants={iconVariant4}
              animate="wave"
              style={{ position: "absolute", top: "30rem", right: "12%" }}
              file={icons["944f1830-d5f3-42bd-9d97-247790e18cef"]}
            />
            <Image
              width={40}
              height={40}
              as={motion.img}
              variants={iconVariant3}
              animate="wave"
              style={{ position: "absolute", top: "34rem", right: "20%" }}
              file={icons["40dc1991-0dde-461c-b6f6-0fe65f99779e"]}
            />
            <Image
              width={40}
              height={40}
              as={motion.img}
              variants={iconVariant2}
              animate="wave"
              style={{ position: "absolute", top: "38rem", right: "15%" }}
              file={icons["cf9cfc3a-a202-416c-a1bf-d7aa15484c0f"]}
            />
            <Image
              width={40}
              height={40}
              as={motion.img}
              variants={iconVariant1}
              animate="wave"
              style={{ position: "absolute", top: "40rem", right: "23%" }}
              file={icons["e6486f9a-8339-451c-bea4-20d70118ed2d"]}
            />
          </Div>
        </Div>
        <TextWrapper
          gap="1rem"
          padding="10rem 1rem 6rem"
          paddingMd="8rem 1rem 3rem"
          position="relative"
          maxWidth="800px"
        >
          <Heading
            as="h1"
            color="white"
            style={{
              textShadow: "0px 4px 25px rgba(33, 38, 79, 0.50)",

              fontSize: "clamp(2rem, 3vw + 1.5rem, 5.2rem)",
            }}
          >
            Succeed with data
          </Heading>
          <Paragraph
            color="white"
            fontSize="1.5rem"
            lineHeight="160%"
            fontWeight={600}
            margin="0 0 1rem"
          >
            Everything you need to connect, automate, manage, and deliver
            real-time data and dashboards to the people who need them most.
          </Paragraph>
          <Button.Agnostic
            background={gradient.powerMetricsButtonAlt}
            boxShadow="0px 8px 16px rgba(75, 87, 197, 0.2), inset 0px -6px 14px rgba(255, 255, 255, 0.4)"
            color={color.indigo600}
            margin="0 0 5rem"
            marginMd="0 0 4rem"
            noticeText="No credit card required"
            noticeColor="white"
            width="max-content"
            data-cta-location="header"
          >
            Get Started Now
          </Button.Agnostic>
          <Paragraph color="white" fontWeight={600} lineHeight={1.6}>
            You are in good company with over 50,000+ decision makers like you.
          </Paragraph>
          <LogoCarousel />
        </TextWrapper>
      </Div>
      <Div
        width="100%"
        margin="6rem auto 8rem"
        marginMd="6rem auto"
        marginSm="4rem auto"
      >
        <Div position="relative" overflow="clip visible">
          <HighGradient
            style={{ minWidth: "2500px" }}
            top="48rem"
            src={imageGradient.cdn}
            loading="lazy"
            hideOnMd
          />
          <Flex
            margin="0 auto"
            alignItems="center"
            textContainer
            center
            position="relative"
          >
            <KlipsLogo height="60" />
            <Heading
              margin="2rem auto 1rem"
              fontSize="3.6rem"
              color={color.blue600}
            >
              All your data works
              <br />
              <Span color={color.blue400}>beautifully in Klipfolio</Span>
            </Heading>
            <Paragraph
              margin="0 auto"
              fontWeight={600}
              lineHeight={1.5}
              fontSize="1.5rem"
              color={color.blue600}
            >
              Get data into everyone&apos;s hands for better decision making.
              Connect to hundreds of services and APIs directly and build highly
              customizable dashboards and reports for your team and clients.
            </Paragraph>
          </Flex>

          <Div margin="3rem auto 0" position="relative" container>
            <GlassyDiv padding="1rem">
              <Div position="relative" style={{ marginBottom: "-4.4%" }}>
                <Image
                  file={images["6acae780-37eb-4243-80c9-01566e47e7ec"]}
                  style={{ clipPath: "inset(0 0 8% 0 round 10px)" }}
                />
              </Div>
            </GlassyDiv>
            <Div
              width="1290px"
              hideOnMd
              left={"-5%"}
              position="absolute"
              top={0}
            >
              <Image file={images["b9e8eaae-d694-4bdf-835d-86daf10be79f"]} />
            </Div>
          </Div>

          <Flex
            width="max-content"
            margin="2rem auto 8rem"
            zIndex={10}
            position="relative"
            flexFlow="row"
            alignItems="center"
            justifyContent="center"
            alignSelf="center"
          >
            <Button.Klips notice={false}>Get Started Now</Button.Klips>

            <Anchor
              href="/klips"
              color={color.blue600}
              hover={color.blue500}
              margin="0 0 0 1rem"
            >
              Learn more
            </Anchor>
          </Flex>

          <Flex
            flexFlow="row"
            flexFlowMd="column"
            gap="2rem"
            margin="4rem auto 8rem"
            position="relative"
            container
          >
            <Testimony
              name="Carolyn S."
              role="People Insights Manager at formstack.com"
              image={images["21dba570-e178-4e0b-ae60-7fb373b554e8"]}
              testimony={`I like how much you can customize the look and feel of your dashboard. Klipfolio connects all of our disparate systems within our HR department. Seeing survey, HRIS, and ATS data in one
            dashboard is really cool.`}
            />
            <Testimony
              name="Andrew W."
              role="Director at IT MOOTI"
              image={images["05d55cc6-3011-4deb-a6fc-fa3da07ab2f3"]}
              testimony={`I build dashboards for clients across many industries and it is by far the best tool I've come across for building KPI reports that are used daily and motivate teams to make progress on business goals.`}
            />
          </Flex>

          <SocialProofSection />
        </Div>
      </Div>
      <Div
        container
        position="relative"
        overflow="hidden"
        margin="0 auto 8rem"
        marginMd="0 auto 6rem"
        marginSm="0 auto 4rem"
        maxWidth="100%"
      >
        <Heading
          style={{ fontSize: "clamp(2rem, 3vw + 1rem, 4rem)" }}
          margin="0 0 3rem"
          marginSm="0 0 2rem"
          as="h2"
          center
        >
          100+ Technology Partners
        </Heading>
        <ServiceLibraryAnimation />
      </Div>
      <NeutralCTA />
    </Layout>
  )
}

HomePage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default HomePage

export const pageQuery = graphql`
  query HomePageQuery {
    gradient: directusFiles(
      directus_id: { eq: "56ded7c2-7a1c-4c29-9a69-5ce23a09f1af" }
    ) {
      id
      cdn
      title
    }
    icons: allDirectusFiles(
      filter: {
        directus_id: {
          in: [
            "b017da8b-6c31-47ff-a77a-9f75cf6fb353"
            "3efdd553-4448-4e9e-9e5f-dabf2647b45c"
            "3aa397c0-c54c-4c24-9d6a-fa2e7641dfe9"
            "9e4c6e11-0e74-45b6-8557-3f3291b6ec1f"
            "944f1830-d5f3-42bd-9d97-247790e18cef"
            "cf9cfc3a-a202-416c-a1bf-d7aa15484c0f"
            "40dc1991-0dde-461c-b6f6-0fe65f99779e"
            "e6486f9a-8339-451c-bea4-20d70118ed2d"
          ]
        }
      }
    ) {
      edges {
        node {
          cdn
          title
          placeholder
          directus_id
        }
      }
    }
    images: allDirectusFiles(
      filter: {
        directus_id: {
          in: [
            "6acae780-37eb-4243-80c9-01566e47e7ec"
            "b9e8eaae-d694-4bdf-835d-86daf10be79f"
            "21dba570-e178-4e0b-ae60-7fb373b554e8"
            "05d55cc6-3011-4deb-a6fc-fa3da07ab2f3"
          ]
        }
      }
    ) {
      edges {
        node {
          cdn
          title
          placeholder
          directus_id
        }
      }
    }
  }
`
